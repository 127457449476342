import React, {Fragment, useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import {get } from '../services/api'
import * as XLSX from 'xlsx';
import moment from 'moment';
import {
  Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input,
  InputGroup, InputGroupAddon, InputGroupText, Badge, ButtonGroup
} from 'reactstrap' 

const TableCandidate = ()=>{
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

    const tableColumns = [
      {
        name: 'Fecha Registro',
        selector: 'Fecha_registro_Action',
        sortable: true,
        center:true,
      },
      {
        name: 'ID Candidato',
        selector: 'candidate_id',
        sortable: true,
        center: false,
      },
      {
        name: 'Tipo Documento',
        selector: 'type_identity',
        sortable: true,
        center: false,
      },
      {
        name: 'Documento de Identidad',
        selector: 'number_identity',
        sortable: true,
        center: false,
      },
      {
        name: 'Nombre Candidato',
        selector: 'Candidato',
        sortable: true,
        center: false,
      },
      {
        name: 'Teléfono',
        selector: 'n_telefono',
        sortable: true,
        center: false,
      },
      {
        name: 'Ciudad',
        selector: 'Ciudad',
        sortable: true,
        center: false,
      },
      {
        name: 'Grado Instrucción',
        selector: 'grado_instruccion_candidato',
        sortable: true,
        center: false,
      },
      {
        name: 'Categoría',
        selector: 'categoria_candidato',
        sortable: true,
        center: false,
      },
      {
        name: 'Origen',
        selector: 'origen_candiate',
        sortable: true,
        center: false,
      },
      {
        name: 'Estatus',
        selector: 'status_candidate',
        sortable: true,
        center: false,
      },
      {
        name: 'Id Posición',
        selector: 'id_Posicion',
        sortable: true,
        center: false,
      },
      {
        name: 'Posición',
        selector: 'Posicion',
        sortable: true,
        center: false,
      },
      {
        name: 'Cliente',
        selector: 'cliente',
        sortable: true,
        center: false,
      },
      {
        name: 'Fecha Entrevista 1',
        selector: 'entrevista_1',
        sortable: true,
        center: false,
      },
      {
        name: 'Fecha Entrevista 2',
        selector: 'entrevista_2',
        sortable: true,
        center: false,
      },
      {
        name: 'Fecha Entrevista 3',
        selector: 'entrevista_3',
        sortable: true,
        center: false,
      },
      {
        name: 'Fecha Incorporación',
        selector: 'Fecha_incorporacion',
        sortable: true,
        center: false,
      },
      {
        name: 'Fecha Salida',
        selector: 'Fecha_salida',
        sortable: true,
        center: false,
      },
      {
        name: 'Recruiter',
        selector: 'recruiter',
        sortable: true,
        center: false,
      },
      {
        name: 'Motivo de Rechazo',
        selector: 'motivo_rechazo',
        sortable: true,
        center: false,
      },
      {
        name: 'Comentario',
        selector: 'Comentario',
        sortable: true,
        center: false,
      },
  ]
    
    useEffect(() => {
      getTable();
    }, [0])

    const getTable = async()=>{
      get('api/candidate/data-table')
        .then(data=>{
          console.log('data final--->', data)
          setListData(data);
        })
        .catch(err=>console.log('error servidor----->', err))
    }

    const handleExport = () => {
      var candidateExcelData = listData.map(candidate => {
        
        return {
          'Fecha Registro' : candidate.Fecha_registro_Action,
          'Id candidato' : candidate.candidate_id,
          'Tipo Documento': candidate.type_identity,
          'Documento de Identidad': candidate.number_identity,
          'Candidato' : candidate.Candidato,
          'N teléfono' : candidate.n_telefono,
          'Ciudad' : candidate.Ciudad,
          'Grado' : candidate.grado_instruccion_candidato,
          'Cateoria' :candidate.categoria_candidato,
          'Origen' : candidate.origen_candiate,
          'Estado' : candidate.status_candidate,
          'Id Posicion' : candidate.id_Posicion,
          'Posicion' : candidate.Posicion,
          'Cliente' : candidate.cliente,
          'Entrevista 1' : candidate.entrevista_1,
          'Entrevista 2' : candidate.entrevista_2,
          'Entrevista 3' : candidate.entrevista_3,
          'Fecha incorporación' : candidate.Fecha_incorporacion,
          'Fecha Salida' : candidate.Fecha_salida,
          'Recruiter' : candidate.recruiter,
          'Motivo Rechazo' : candidate.motivo_rechazo,
          'Comentarios' : candidate.Comentario
        }
      })
      var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `rrhh_candidatos_${moment().format()}.xlsx`);
    }

    return (
        <Fragment>
          <Container fluid={true}>
            <Row className="custom-row-table">
              <Col sm="12" className="custom-col-table">
                <Card className="custom-card-table">
                  <CardBody className="custom-card-body-table">
                  <div className="toggle-container">
                  <Button color='success' className="btn-search pull-right"
                    onClick={handleExport}>
                      <i className="fa fa-file-excel-o"></i> 
                      {" Exportar"}
                  </Button>
                </div>
                    <DataTable className="table-responsive"
                          key={"id_row"}
                          data={listData}
                          pagination
                          columns={tableColumns}
                          persistTableHead
                          searchable={true}
                          noDataComponent={<div>No hay datos disponibles</div>}
                          progressPending={loading}
                          progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    )
}

export default TableCandidate;