import React, { Fragment, useState, useEffect, useRef, useMemo  } from 'react';
import Breadcrumb from '../layout/breadcrumb'
import { Container, Row, Col, Card, Label, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, ButtonGroup
} from 'reactstrap'
import { postWithData, get } from '../services/api'

const CandidateList = () => {

  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));
  const [nameUserSesion, setNameUserSesion] = useState(localStorage.getItem('Name'));
  const [listPositionData, setListPositionData] = useState([]);
  const [listPositionExcelData, setListPositionExcelData] = useState([]);
  const [listPositionDataReserv, setListPositionDataReserv] = useState([]);
  const [listPositionDataReservForFilter, setListPositionDataReservForFilter] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [listSubStatus, setListSubStatus] = useState([]);
  const [listStatusReject, setListStatusReject] = useState([]);
  const openModalDetail = () => setVisibleModalDetail(!visibleModalDetail);
  const [visibleModalDetail, setVisibleModalDetail] = useState(null);

  useEffect(() => {
    getListCandidate();
    getListStatus();
    getListStatusReject();
  }, [0])
 
  
  const getListCandidate = async () => {

    try {
      let dataList = [];
      let dataListExcel = [];

      get("api/position/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach((element, index) => {
            let idPosicion = element.id;
            
            let item = {
              "id": element.id,
              "checkbox": false,
              "name": element.name,
              "code_position": element.code_position ?? '',
              "name_client": element.name_client ?? '',
              "description": element.description,
              "responsable_client": element.responsable_client,
              "responsable":(element.responsable == null || element.responsable == undefined) ? "" : (element.responsable).replace("null", ''),
              "priority": element.priority ?? '',
              "range_salary": element.range_salary,
              "date_register": element.date_register,
              "enabled": element.state_value,
              "sub_state": element.sub_state_value,
              "remote_work": (element.remote_work == 1) ? "Si" : "No",
              "is_portal": element.is_portal,
              "desirable": element.desirable,
              "indispensable": element.indispensable,
              "technology_minimal": element.technology_minimal,
              "technology_desirable": element.technology_desirable,
              "schedule": element.schedule,
              "desirable_requirements": element.desirable_requirements,
              "required_requirements": element.required_requirements,
              "orientative": element.orientative,
            }
            dataList.push(item);
          });
          setListPositionData(dataList);
          setListPositionDataReserv(dataList);
          setListPositionDataReservForFilter(dataList);
          setListPositionExcelData(dataListExcel);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListStatus = async () => {
    let dataRes = [];
    try {
        get("api/position/state/list").then((result) => {
            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push({value: element.id, label: element.value});
                });
                setListStatus(dataRes);
            }
        });
    } catch (error) {
        console.log(error)
    }
  }

  const getListStatusReject = async () => {
    let dataReject = [];
    try {
      get("api/status/list").then((result) => {
        if (result.success == true) {
          result.data.forEach(element => {
            if(element.name.indexOf('Rechazado') == 0){
              dataReject.push({value: element.id, label: element.name});
            }
          });
          setListStatusReject(dataReject);
        } 
      });

    } catch (error) {
        console.log(error)
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="" title="Lista de Vacantes - Test" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </Fragment>
  );
};

export default CandidateList;