import React, { Fragment, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Breadcrumb from '../layout/breadcrumb'

import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx';
import moment from 'moment';
import { toast } from 'react-toastify';
import { tableData } from '../data/dummyTableData'
import {
  Container, Row, Col, Card, CardBody, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap'
import { get } from '../services/api'

import '../assets/css/tablestyle.css';


const CandidateList = () => {

  const [data, setData] = useState(tableData)
  const [listCandidateData, setListCandidateData] = useState([]);
  // const [listTechnologiesData, setListTechnologiesData] = useState([]);
  const [listCandidateDataReserv, setListCandidateDataReserv] = useState([]);
  const [listCandidateDataReservForFilter, setListCandidateDataReservForFilter] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [visibleSearch, setVisibleSearch] = useState(true);
  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));
  
  const [idCandidate, setIdCandidate] = useState('');
  const [visibleModalDeleteCandidate, setVisibleModalDeleteCandidate] = useState(false);
  

  const [searchTextName, setSearchTextName] = useState('');
  const [searchTextPhone, setSearchTextPhone] = useState('');
  const [searchTextEmail, setSearchTextEmail] = useState('');
  const [searchTextOrigin, setSearchTextOrigin] = useState('');
  const [searchTextMainMarket, setSearchTextMainMarket] = useState('');
  const [searchTextPortal, setSearchTextPortal] = useState('');
  const [searchTextClient, setSearchTextClient] = useState('');
  const [searchTextPosition, setSearchTextPosition] = useState('');
  const [searchTextStatus, setSearchTextStatus] = useState('');
  const [searchTextTech, setSearchTextTech] = useState('');
  const [searchTextCity, setSearchTextCity] = useState('');
  const [togglePortalChange, setTogglePortalChange] = useState("");
  const [isFilterPortal, setIsFilterPortal] = useState(false);
  const [isFilterNoPortal, setIsFilterNoPortal] = useState(false);
  
  let listTechnologiesData;
  useEffect(() => {
    getListTechnologies();
    getListCandidate();
    console.log(perfilActive)
  }, [0])

  useEffect(() => {
    clearInputs();
    if(togglePortalChange === 1){
      setListCandidateData(listCandidateDataReservForFilter.filter((item) => item.is_portal === 1));
      setListCandidateDataReserv(listCandidateDataReservForFilter.filter((item) => item.is_portal === 1));
    }else if (togglePortalChange === 2){
      setListCandidateData(listCandidateDataReservForFilter.filter((item) => item.is_portal === 0));
      setListCandidateDataReserv(listCandidateDataReservForFilter.filter((item) => item.is_portal === 0));
    } else {
      setListCandidateData(listCandidateDataReservForFilter);
      setListCandidateDataReserv(listCandidateDataReservForFilter);
    }
  }, [togglePortalChange])

  const clearInputs = () => {
    setSearchTextName('');
    setSearchTextPhone('');
    setSearchTextEmail('');
    setSearchTextOrigin('');
    setSearchTextPortal('');
    setSearchTextClient('');
    setSearchTextPosition('');
    setSearchTextStatus('');
    setSearchTextTech('')
    setSearchTextMainMarket('')
    setSearchTextCity('')
  };

  const getListCandidate = async () => {

    try {
      let dataList = [];

      get("api/candidate/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            //let statusNameShow = (element.number_interview != null)? <span>{element.status_name}<br/>{element.number_interview} {"Entrevista"}</span> : <span>{element.status_name}</span>;
            let statusNameShow = (element.status_name !== null)? <span>{element.status_name}</span> : <span>{"Registrado"}</span>;

            const GetButtons = () => {
              let deleteBtn = '';
                if (perfilActive === 'Administrador'){
                  deleteBtn =   <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar' onClick={() => openModalDeleteCandidate(element.id)}><i className= "icon-trash"></i></Button>
                }

              return (
                <ButtonGroup>
                  <Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px'  }} color='red'>
                    <Link to={`/candidate/history-candidate/${element.id}`} style={{ color:'#000', textDecoration:'none'  }} title='Agregar/Ver Historial'>
                      <i className="icon-eye"></i>
                    </Link>
                  </Button>
                  {deleteBtn}
                  {/*<Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px'  }} color='red' title='Enviar Candidato a Cliente'><i className="icon-share-alt"></i></Button>*/}
                </ButtonGroup>
              )
            }

            let fullname = element.name_candidate + ' ' + element.lastname + ' ' + element.second_lastname;
            let candidateTechnologies = ""
            

            if(element.technology !== null){
              candidateTechnologies= element.technology.split(',').map((x)=>{
                return listTechnologiesData.find((technology => technology.id == x.trim()));
              }).map(technology => technology.name)
              .join(", ");
            }

            let item = {
              "id_row": element.id_row,
              "ud": element.id,
              "type_identity": element.type_identity,
              "number_identity": element.number_identity,
              "name_candidate": fullname.replace(/\s*null\s*/g, ''),
              //"name_candidate": (element.calling_again == false)? <span className={"text-through"}>{fullname.replace(/\s*null\s*/g, '')}</span> : fullname.replace(/\s*null\s*/g, ''),
              "lastname": element.lastname,
              "second_lastname": element.second_lastname ?? '',
              "phone": element.phone ?? '',
              "email": element.email ?? '',
              "city": element.city ?? '',
              "address": element.address,
              "academic": element.academic,
              "category": element.category,
              "technology": candidateTechnologies,
              "languages": element.languages,
              "salary": element.salary,
              //"id_tracking": element.id_tracking,
              "description": element.description,
              "position_name": element.position_name ?? '',
              "status_id": element.status_id,
              "is_portal": element.is_portal,
              "origin": element.origin_name ?? '',
              "main_market": element.main_market_name ?? '',
              "name_client": element.name_client ?? '',
              "actions": <GetButtons />,
              "status_name": <p style={{ color: element.status_color, fontWeight:500}}>{statusNameShow}</p>,
              "recruiter_inicial": element.recruiter_inicial ?? '',
              "date_register_candidate": element.date_register_inicial,
              "date_incorporation_candidate": element.date_incorporacion_candidate,
              "estado_candidate" : element.status_name
            }
            dataList.push(item);
          });
          setListCandidateData(dataList);
          setListCandidateDataReserv(dataList);
          setListCandidateDataReservForFilter(dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListTechnologies = async () => {
    let dataRes = [];
    try {
        get("api/technology/list", data).then((result) => {

            if (result.success == true) {
                result.data.forEach(element => {
                    dataRes.push(element.name)
                });
                listTechnologiesData = result.data;
            }
        });
    } catch (error) {
        console.log(error)
    }
}

  const tableColumns = [
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center:true,
    },
    {
      name: 'Nombre',
      selector: 'name_candidate',
      sortable: true,
      center: false,
      minWidth: '200px'
    },
    {
      name: 'Telefono',
      selector: 'phone',
      sortable: true,
      center: false,
    },
    {
      name: 'Tecnologías',
      selector: 'technology',
      sortable: true,
      center: false,
      minWidth: '200px'
    },
    /*{
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: false,
      minWidth: '200px'
    },*/
    {
      name: 'Estado',
      selector: 'status_name',
      sortable: true,
      center: true,
      minWidth: '300px'
    },
    {
      name: 'Origen',
      selector: 'origin',
      sortable: true,
      center: false,
    },
    {
      name: 'Provincia',
      selector: 'city',
      sortable: true,
      center: false,
    },
    {
      name: 'Mercado Principal',
      selector: 'main_market',
      sortable: true,
      center: false,
    },
    /*{
      name: 'Portal',
      selector: 'is_portal',
      sortable: true,
      center: false,
    },*/
    {
      name: 'Cliente',
      selector: 'name_client',
      sortable: true,
      center: false,
    },
    {
      name: 'Vacante',
      selector: 'position_name',
      sortable: true,
      center: false,
      minWidth: '200px'
    },
    {
      name: 'Fecha de Registro',
      selector: 'date_register_candidate',
      sortable: true,
      center: true,
      minWidth: '250px'
    },
    {
      name: 'Fecha de Incorporación',
      selector: 'date_incorporation_candidate',
      sortable: true,
      center: true,
      minWidth: '250px'
    },
    {
      name: 'Reclutador',
      selector: 'recruiter_inicial',
      sortable: true,
      center: true,
      minWidth: '200px'
    }
  ]

  const tableColumnsComerical = [
    {
      name: 'Cliente',
      selector: 'name_client',
      sortable: true,
      center: false,
    },
    {
      name: 'Vacante',
      selector: 'position_name',
      sortable: true,
      center: false,
      width: '200px'
    },
    {
      name: 'Reclutador',
      selector: 'recruiter_inicial',
      sortable: true,
      center: true,
      minWidth: '200px'
    },
    {
      name: 'Fecha de ingreso',
      selector: 'date_register_candidate',
      sortable: true,
      center: true,
      minWidth: '250px'
    },
    {
      name: 'Nombre',
      selector: 'name_candidate',
      sortable: true,
      center: false,
      minWidth: '200px'
    },
    {
      name: 'Telefono',
      selector: 'phone',
      sortable: true,
      center: false,
    },
    {
      name: 'Tecnologías',
      selector: 'technology',
      sortable: true,
      center: false,
      minWidth: '200px'
    },
    /*{
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: false,
      minWidth: '200px'
    },*/
    {
      name: 'Estado',
      selector: 'status_name',
      sortable: true,
      center: true,
      minWidth: '300px'
    },
    {
      name: 'Origen',
      selector: 'origin',
      sortable: true,
      center: false,
    },
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center:true,
    }
    
  ]

  /*
  const handleToggleVisibleSearch = () => {
    setVisibleSearch(!visibleSearch);
  };*/
  const openModalDeleteCandidate = (idCandidate) => {
    setIdCandidate(idCandidate);
    setVisibleModalDeleteCandidate(true);
  }

  const deleteCandidate = () => {
    let user_id = localStorage.getItem('Id');
    try {
      get("api/candidate/delete/" + idCandidate + '/' + user_id).then((result) => {

        setVisibleModalDeleteCandidate(false);
        toast.success(`Se elimino de forma satisfactoria.`);
        window.location.href = window.location.href;      
      });
    } catch (error) {
      console.log(error)
    }
  }

  const closeModalDeleteCandidate = () => {
    setVisibleModalDeleteCandidate(!visibleModalDeleteCandidate);
    setIdCandidate('');
  };

  const handleTogglePortal = () => {
    if((!isFilterPortal===true)) {
      setTogglePortalChange(1);
    } else {
      setTogglePortalChange(3);
    }
    setIsFilterNoPortal(false)
    setIsFilterPortal(!isFilterPortal);
  };

  const handleToggleNoPortal = () => {
    if((!isFilterNoPortal===true)) {
      setTogglePortalChange(2);
    } else {
      setTogglePortalChange(3);
    }
    setIsFilterPortal(false);
    setIsFilterNoPortal(!isFilterNoPortal);
  };

  const getBadgeTextContent = (badgeElement) => {
    const childrenArray = React.Children.toArray(badgeElement.props.children);
    return childrenArray.map((child) => child.props ? child.props.children : child).join('');
  };

  const handleSearch = (e) => {

    const tempTableData = listCandidateDataReserv;
    let textSearch = e.target.value;
    let filteredData;
    let tempSearchTextName = searchTextName;
    let tempSearchTextPhone = searchTextPhone;
    let tempSearchTextEmail = searchTextEmail;
    let tempSearchTextOrigin = searchTextOrigin;
    let tempSearchTextPortal = searchTextPortal;
    let tempSearchTextClient = searchTextClient;
    let tempSearchTextPosition = searchTextPosition;
    let tempSearchTextStatus = searchTextStatus;
    let tempSearchTextTech = searchTextTech;
    let tempSearchTextMainMarket= searchTextMainMarket;
    let tempSearchTextCity= searchTextCity;

    switch (e.target.name) {
      case "name_search":
        setSearchTextName(textSearch)
        tempSearchTextName = textSearch;
        break;
      case "phone_search":
        setSearchTextPhone(textSearch)
        tempSearchTextPhone = textSearch;
        break;
      case "email_search":
        setSearchTextEmail(textSearch)
        tempSearchTextEmail = textSearch;
        break;
      case "origin_search":
        setSearchTextOrigin(textSearch)
        tempSearchTextOrigin = textSearch;
        break;
      case "portal_search":
        setSearchTextPortal(textSearch)
        tempSearchTextPortal = textSearch;
        break;
      case "client_search":
        setSearchTextClient(textSearch)
        tempSearchTextClient = textSearch;
        break;
      case "position_search":
        setSearchTextPosition(textSearch)
        tempSearchTextPosition = textSearch;
        break;
      case "status_search":
        setSearchTextStatus(textSearch)
        tempSearchTextStatus = textSearch;
        break;
      case "tech_search":
        setSearchTextTech(textSearch)
        tempSearchTextTech = textSearch;
        break;
      case "main_market_search":
        setSearchTextMainMarket(textSearch)
        tempSearchTextMainMarket = textSearch;
        break;
      case "city_search":
        setSearchTextCity(textSearch)
        tempSearchTextCity = textSearch;
        break;
        
    }

    filteredData = tempTableData.filter((item) => item.name_candidate.toLowerCase().includes((tempSearchTextName).toLowerCase()));
    filteredData = filteredData.filter((item) => item.phone.toLowerCase().includes((tempSearchTextPhone).toLowerCase()));
    filteredData = filteredData.filter((item) =>  item.email.toLowerCase().includes((tempSearchTextEmail).toLowerCase()));
    filteredData = filteredData.filter((item) => item.origin.toLowerCase().includes((tempSearchTextOrigin).toLowerCase()));
    // filteredData = filteredData.filter((item) => item.is_portal.toLowerCase().includes((tempSearchTextPortal).toLowerCase()));
    filteredData = filteredData.filter((item) => item.name_client.toLowerCase().includes((tempSearchTextClient).toLowerCase()));
    filteredData = filteredData.filter((item) => item.position_name.toLowerCase().includes((tempSearchTextPosition).toLowerCase()));
    filteredData = filteredData.filter((item) => getBadgeTextContent(item.status_name).toLowerCase().includes((tempSearchTextStatus).toLowerCase()));
    
    filteredData = filteredData.filter((item) => item.technology.toLowerCase().includes((tempSearchTextTech).toLowerCase()));
    filteredData = filteredData.filter((item) => item.main_market.toLowerCase().includes((tempSearchTextMainMarket).toLowerCase()));
    filteredData = filteredData.filter((item) => item.city.toLowerCase().includes((tempSearchTextCity).toLowerCase()));
    setListCandidateData(filteredData);

  };

  const handleExport = () => {
    var candidateExcelData = listCandidateData.map(candidate => {
      return {
        "Nombre": candidate.name_candidate,
        "Teléfono": candidate.phone,
        "Tecnologías": candidate.technology,
        "Estado": candidate.estado_candidate,
        "Origen": candidate.origin,
        "Cliente": candidate.name_client,
        "Vacante": candidate.position_name,
        "Fecha de ingreso": candidate.date_register_candidate,
        "Reclutador": candidate.recruiter_inicial
      }
    })
    var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `rrhh_candidatos_${moment().format()}.xlsx`);
  }

  return (
    <Fragment>
      <Breadcrumb parent="" title="Lista de Candidatos" />
      <Container fluid={true}>
        <Row className="custom-row-table">
          <Col sm="12" className="custom-col-table">
            <Card className="custom-card-table">
              <CardBody className="custom-card-body-table">
                <div className="toggle-container">
                  {/*<Button className="btn-search" color="primary" 
                  onClick={handleToggleVisibleSearch}>{"Buscar Registro"}
                  </Button>*/}
                  <Button style={{ marginRight: '10px' }}  className="btn-search" 
                    color={isFilterPortal ? 'success' : 'primary' } 
                    onClick={handleTogglePortal}>{"Portal"}
                  </Button> 
                  <Button className="btn-search" 
                    color={isFilterNoPortal ? 'success' : 'primary' } 
                    onClick={handleToggleNoPortal}>{"No Portal"}
                  </Button>
                  <Button color='success' className="btn-search pull-right"
                    onClick={handleExport}>
                      <i className="fa fa-file-excel-o"></i> 
                      {" Exportar"}
                  </Button>
                  <div className={`content ${visibleSearch ? 'visible' : 'hidden'}`}>
                    <Row>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="name_search" value={searchTextName} onChange={handleSearch} placeholder="Nombre" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="phone_search" value={searchTextPhone} onChange={handleSearch} placeholder="Teléfono" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="status_search" value={searchTextStatus} onChange={handleSearch} placeholder="Estado" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="origin_search" value={searchTextOrigin} onChange={handleSearch} placeholder="Origen" />
                        </div>
                      </Col>
                      {/* <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="portal_search" value={searchTextPortal} onChange={handleSearch} placeholder="Portal" />
                        </div>
                      </Col> */}
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="client_search" value={searchTextClient} onChange={handleSearch} placeholder="Cliente" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="position_search" value={searchTextPosition} onChange={handleSearch} placeholder="Vacante" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="email_search" value={searchTextEmail} onChange={handleSearch} placeholder="Email" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="tech_search" value={searchTextTech} onChange={handleSearch} placeholder="Tecnología" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="main_market_search" value={searchTextMainMarket} onChange={handleSearch} placeholder="Mercado Principal" />
                        </div>
                      </Col>
                      <Col className="mb-3" sm="2">
                        <div className="search-container">
                          <input type="text" className="form-control" name="city_search" value={searchTextCity} onChange={handleSearch} placeholder="Provincia" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <DataTable className="table-responsive"
                  key={"id_row"}
                  data={listCandidateData}
                  pagination
                  columns={(perfilActive == 'Comercial')? tableColumnsComerical : tableColumns}
                  persistTableHead
                  searchable={true}
                  paginationPerPage={30}  
                  noDataComponent={<div>No hay datos disponibles</div>}
                  progressPending={loading}
                  progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={visibleModalDeleteCandidate} toggle={openModalDeleteCandidate}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalDeleteCandidate}>
            {"Elimiar Candidato"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                {"¿Estas seguro de eliminar este candidato?"}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={deleteCandidate} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModalDeleteCandidate}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

    </Fragment>
  );

};

export default CandidateList;