import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../layout/breadcrumb'
import DataTable from 'react-data-table-component'
import {Container, Row, Col, Card, CardBody, Button} from 'reactstrap'
import { postWithData, get } from '../services/api'

import '../assets/css/tablestyle.css';
import { Field, formatQuery, RuleGroupType } from 'react-querybuilder';
import { defaultValidator, QueryBuilder } from 'react-querybuilder';
import { defaultOperators } from 'react-querybuilder';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import 'react-querybuilder/dist/query-builder.css';
import '../assets/css/builderstyle.css';
import * as XLSX from 'xlsx';
import moment from 'moment';

const CandidateList = () => {

  const [listCandidateData, setListCandidateData] = useState([]);
  const [listPositionData, setListPositionData] = useState([]);
  const [listStatusData, setListStatusData] = useState([]);
  const [listUserData, setListUserData] = useState([]);
  const [listTechData, setListTechData] = useState([]);
  const [listMainMarketData, setListMainMarketData] = useState([]);
  const [listLangData, setListLangData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listUserInactiveData, setListUserInactiveData] = useState([]);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, '0'); // Agrega un 0 al principio si el mes es menor a 10
  const currentDay = String(today.getDate()).padStart(2, '0'); // Agrega un 0 al principio si el día es menor a 10

  const initialQuery = {
    combinator: 'and',
    rules: [
      {
        field: "tracking_candidates.user_id",
        valueEditorType: 'select',
        operator: "="
      },
      {
        field: "CAST(tracking_candidates.date_register AS DATE)",
        value: `${currentYear}-${currentMonth}-${currentDay},${currentYear}-${currentMonth}-${currentDay}`,
        operator: "between"
      }
    ]
  };

  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    getListCandidate();
    getListPosition();
    getListStatus();
    getListTech();
    getListLang();
    getListUser();
    getListUserInactive();
    getListMainMarket();
  }, [0])

  const getListCandidate = async () => {

    let queryBase = "SELECT  DISTINCT candidates.*,  (SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM technologies A INNER JOIN tech_candidate B ON A.id = B.technology_id WHERE B.candidate_id = candidates.id) AS tecnho,(SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM positions A INNER JOIN position_candidate B ON A.id = B.position_id WHERE B.candidate_id = candidates.id) AS vacante, (SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM clients A INNER JOIN positions B ON A.id = B.client_id INNER JOIN position_candidate C ON B.id = C.position_id  WHERE C.candidate_id = candidates.id) AS cliente, users.name as reclutador FROM candidates LEFT JOIN tracking_candidates ON tracking_candidates.candidate_id = candidates.id LEFT JOIN lang_candidate ON lang_candidate.candidate_id = candidates.id LEFT JOIN tech_candidate ON tech_candidate.candidate_id = candidates.id LEFT JOIN users ON users.id = tracking_candidates.user_id LEFT JOIN technologies ON technologies.id = tech_candidate.technology_id LEFT JOIN position_candidate ON position_candidate.candidate_id = candidates.id LEFT JOIN positions ON positions.id = position_candidate.position_id WHERE "
    let queryResult = queryBase+' candidates.id IS NOT NULL ORDER BY candidates.id DESC'

    console.log(queryResult)
    let data = {
      query: queryResult
    }

    try {
      let dataList = [];

      postWithData("api/searchQuery", data).then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {
            //console.log(element)
            //let statusNameShow = (element.number_interview != null)? <span>{element.status_name}<br/>{element.number_interview} {"Entrevista"}</span> : <span>{element.status_name}</span>;
            let statusNameShow = <span>{element.status_name}</span>;

            const GetButtons = () => {
              return (
                <Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px' }} color='red'>
                  <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', textDecoration: 'none' }} title='Agregar/Ver Historial'>
                    <i className="icon-eye"></i>
                  </Link>
                </Button>
              )
            }

            let fullname = element.name + ' ' + element.lastname + ' ' + element.second_lastname;
            let inicialesRecruiter = '';
            /*if(element.reclutador != ''){
              let nameRecruiter = element.reclutador.split(' ');
              inicialesRecruiter = nameRecruiter.map(nameRecruiter => nameRecruiter[0]).join('');
            }*/

            let item = {
              "id": element.id,
              "name_candidate": fullname.replace('null', ''),
              "lastname": element.lastname,
              "second_lastname": element.second_lastname,
              "phone": element.phone ?? '',
              "tecnho": element.tecnho ?? '',
              "vacante": element.vacante ?? '',
              "origin": '',
              "email": element.email ?? '',
              "cliente": element.cliente ?? '',
              "reclutador": inicialesRecruiter,
              "actions": <GetButtons />
            }
            dataList.push(item);
          });
          setListCandidateData(dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListPosition = async () => {

    try {
      let dataList = [];

      get("api/position/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListPositionData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListStatus = async () => {

    try {
      let dataList = [];

      get("api/status/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListStatusData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListLang = async () => {

    try {
      let dataList = [];

      get("api/language/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListLangData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListTech = async () => {

    try {
      let dataList = [];

      get("api/technology/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListTechData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListMainMarket = async () => {

    try {
      let dataList = [];

      get("api/mainmarket/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name
            }
            dataList.push(item);
          });
          setListMainMarketData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListUser = async () => {

    try {
      let dataList = [];

      get("api/user/list").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name_user
            }
            dataList.push(item);
          });
          setListUserData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListUserInactive = async () => {

    try {
      let dataList = [];

      get("api/user/list/inactive").then((result) => {
        if (result.data.length > 0) {

          result.data.forEach(element => {

            let item = {
              name: element.id,
              label: element.name_user
            }
            dataList.push(item);
          });
          setListUserInactiveData(dataList);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log("ddd")
      // Simular un clic en el botón "Buscar"
      buscar();
    }
  };

  const buscar = () => {
    let queryWhereSql = formatQuery(query, 'sql');
    let queryBase = "SELECT  DISTINCT candidates.*,  (SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM technologies A INNER JOIN tech_candidate B ON A.id = B.technology_id WHERE B.candidate_id = candidates.id) AS tecnho,(SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM positions A INNER JOIN position_candidate B ON A.id = B.position_id WHERE B.candidate_id = candidates.id) AS vacante, (SELECT GROUP_CONCAT(A.name SEPARATOR ', ') FROM clients A INNER JOIN positions B ON A.id = B.client_id INNER JOIN position_candidate C ON B.id = C.position_id  WHERE C.candidate_id = candidates.id) AS cliente, users.name as reclutador FROM candidates LEFT JOIN tracking_candidates ON tracking_candidates.candidate_id = candidates.id LEFT JOIN lang_candidate ON lang_candidate.candidate_id = candidates.id LEFT JOIN tech_candidate ON tech_candidate.candidate_id = candidates.id LEFT JOIN users ON users.id = tracking_candidates.user_id LEFT JOIN technologies ON technologies.id = tech_candidate.technology_id LEFT JOIN position_candidate ON position_candidate.candidate_id = candidates.id LEFT JOIN positions ON positions.id = position_candidate.position_id WHERE "

    let queryResult = queryBase + '' + queryWhereSql

    console.log(queryResult)
    let data = {
      query: queryResult
    }

    try {
      let dataList = [];

      postWithData("api/searchQuery", data).then((result) => {
        setLoading(true);
        if (result.data.length > 0) {

          result.data.forEach(element => {
            //console.log(element)
            const GetButtons = () => {
              return (
                <Button style={{ padding: 0, margin: 5, hight: '35px', width: '35px' }} color='red'>
                  <Link to={`/candidate/history-candidate/${element.id}`} style={{ color: '#000', textDecoration: 'none' }} title='Agregar/Ver Historial'>
                    <i className="icon-eye"></i>
                  </Link>
                </Button>
              )
            }

            let fullname = element.name + ' ' + element.lastname + ' ' + element.second_lastname;
            let inicialesRecruiter = '';
            /*if(element.reclutador != ''){
              let nameRecruiter = element.reclutador.split(' ');
              inicialesRecruiter = nameRecruiter.map(nameRecruiter => nameRecruiter[0]).join('');
            }*/

            let item = {
              "id": element.id,
              "name_candidate": fullname.replace('null', ''),
              "lastname": element.lastname,
              "second_lastname": element.second_lastname,
              "phone": element.phone ?? '',
              "tecnho": element.tecnho ?? '',
              "vacante": element.vacante ?? '',
              "origin": '',
              "email": element.email ?? '',
              "cliente": element.cliente ?? '',
              "reclutador": inicialesRecruiter,
              "actions": <GetButtons />,
            }
            dataList.push(item);
          });
          setListCandidateData(dataList);
          setLoading(false);
        } else {
          setListCandidateData([]);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error)
    }
  };

  const fields = [
    {
      name: 'candidates.name',
      label: 'Nombre',
      placeholder: 'Ingrese nombre',
    },
    {
      name: 'candidates.lastname',
      label: 'Primer Apellido',
      placeholder: 'Ingrese primer apellido',
    },
    {
      name: 'candidates.second_lastname',
      label: 'Segundo Apellido',
      placeholder: 'Ingrese segundo apellido',
    },
    {
      name: 'candidates.phone',
      label: 'Teléfono',
      placeholder: 'Ingrese telefono',
    },
    {
      name: 'candidates.email',
      label: 'Email',
      placeholder: 'Ingrese email',
    },
    {
      name: 'candidates.city',
      label: 'Ciudad',
      placeholder: 'Ingrese ciudad',
    },
    /*{ 
      name: 'candidates.postal_code', 
      label: 'Código Postal',
      placeholder: 'Ingrese código postal',
    },*/
    //{ name: 'age', label: 'Age', inputType: 'number', validator },
    /*{
      name: 'isMusician',
      label: 'Is a musician',
      valueEditorType: 'checkbox',
      operators: defaultOperators.filter((op) => op.name === '='),
      defaultValue: false,
    },*/
    {
      name: 'candidates.salary',
      label: 'Salario',
    },
    {
      name: 'candidates.description',
      label: 'Observación / Comentarios',
      operators: defaultOperators.filter((op) => op.name === 'contains'),
    },
    {
      name: 'candidates.comment_client',
      label: 'Comentario para cliente',
      operators: defaultOperators.filter((op) => op.name === 'contains'),
    },
    /*
    { 
      name: 'positions.id', 
      label: 'Vacante', 
      valueEditorType: 'select',
      values: listPositionData
    },*/
    {
      name: 'tracking_candidates.status_id',
      label: 'Status Candidato',
      valueEditorType: 'select',
      values: listStatusData
    },
    {
      name: 'positions.name',
      label: 'Vacante',
      operators: defaultOperators.filter((op) => op.name === 'contains'),
    },
    /*{ 
      name: 'tech_candidate.technology_id', 
      label: 'Tecnología', 
      valueEditorType: 'select',
      values: listTechData
    },*/
    {
      name: 'technologies.name',
      label: 'Tecnología',
      operators: defaultOperators.filter((op) => op.name === 'contains'),
    },
    {
      name: 'candidates.main_market_id',
      label: 'Mercado Principal',
      valueEditorType: 'select',
      values: listMainMarketData
    },
    {
      name: 'tracking_candidates.user_id',
      label: 'Reclutador',
      valueEditorType: 'select',
      values: listUserData
    },
    {
      name: 'tracking_candidates.user_ids',
      label: 'Reclutador Inactivo',
      valueEditorType: 'select',
      values: listUserInactiveData
    },
    
    {
      name: 'lang_candidate.lang_id',
      label: 'Idioma',
      valueEditorType: 'select',
      values: listLangData
    },
    {
      name: 'CAST(tracking_candidates.date_register AS DATE)',
      label: 'Fecha de Registro',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_1 AS DATE)',
      label: 'Fecha de Entrevista 1',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_2 AS DATE)',
      label: 'Fecha de Entrevista 2',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_3 AS DATE)',
      label: 'Fecha de Entrevista 3',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_4 AS DATE)',
      label: 'Fecha de Entrevista 4',
      inputType: 'date'
    },
    {
      name: 'CAST(tracking_candidates.date_inerview_5 AS DATE)',
      label: 'Fecha de Entrevista 5',
      inputType: 'date'
    }
  ];

  const handleExport = () => {
    var candidateExcelData = listCandidateData.map(candidate => {
      return {
        "Nombre": candidate.name_candidate,
        "Teléfono": candidate.phone,
        "Email": candidate.email,
        "Tecnología": candidate.tecnho,
        "Vacante": candidate.vacante,
        "Cliente": candidate.cliente,
      }
    })
    var worksheet = XLSX.utils.json_to_sheet(candidateExcelData);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `rrhh_candidatos_busqueda_avanzada_${moment().format()}.xlsx`);
  }

  const tableColumns = [
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'name_candidate',
      sortable: true,
      center: false,
      minWidth: '200px'
    },
    {
      name: 'Teléfono',
      selector: 'phone',
      sortable: true,
      center: false,
      minWidth: '100px'
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: false,
      minWidth: '100px'
    },
    {
      name: 'Tecnología',
      selector: 'tecnho',
      sortable: true,
      center: false,
      minWidth: '100px'
    },
    {
      name: 'Vacante',
      selector: 'vacante',
      sortable: true,
      center: false,
      minWidth: '100px'
    },
    {
      name: 'Cliente',
      selector: 'cliente',
      sortable: true,
      center: false,
      minWidth: '100px'
    },
    {
      name: 'Reclutador',
      selector: 'reclutador',
      sortable: true,
      center: false,
      minWidth: '100px'
    }

    
  ]

  return (
    <Fragment>
      <Breadcrumb parent="" title="Búsqueda avanzada" />
      <Container fluid={true}>
        <Row className="custom-row-table">
          <Col sm="12" className="custom-col-table">
            <Card className="custom-card-table">
              <CardBody className="custom-card-body-table">
                <Row>
                  <Col>
                  <Button color='success' className="btn-search pull-right"
                    onClick={handleExport}>
                    <i className="fa fa-file-excel-o"></i>
                    {" Exportar"}
                  </Button>
                  </Col>
                </Row>
                <div>
                  <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                    <QueryBuilder
                      fields={fields}
                      query={query}
                      onQueryChange={(q) => setQuery(q)}
                      showCombinatorsBetweenRules
                      validator={defaultValidator}
                      controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                      onKeyPress={handleKeyPress}
                    />
                  </QueryBuilderDnD>
                  <br />
                  <Button className="btn-search" id="btnBuscar" color="primary" onClick={buscar}>{"Buscar"}</Button>

                </div>
                <DataTable className="table-responsive"
                  key={"id"}
                  data={listCandidateData}
                  pagination
                  columns={tableColumns}
                  persistTableHead
                  searchable={true}
                  noDataComponent={<div>No hay datos disponibles</div>}
                  progressPending={loading}
                  progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CandidateList;