import React, { Fragment, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom';
import Breadcrumb from '../layout/breadcrumb'
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { tableData } from '../data/dummyTableData'
import Select from 'react-select'
import differenceBy from 'lodash/differenceBy';
import { Container, Row, Col, Card, CardBody, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, CardHeader, ButtonGroup } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { postWithData, get, postWithDataMultiform } from '../services/api'
import '../assets/css/tablestyle.css';
import { format } from 'date-fns';
import ModalEditTracking from './modal-edit-tracking';

const History = (props) => {

  let { id_candidate } = useParams();

  const [perfilActive, setPerfilActive] = useState(localStorage.getItem('NameRole'));
  const [idUserSesion, setIdUserSesion] = useState(localStorage.getItem('Id'));

  const { register, handleSubmit, errors } = useForm();
  const [data, setData] = useState(tableData)

  const [listCandidateTracking, setListCandidateTracking] = useState([]);
  const [listCandidateTrackingLast, setListCandidateTrackingLast] = useState([]);

  const [listCandidateTrackingShow, setListCandidateTrackingShow] = useState([]);

  const [listStatusId, setListStatusId] = useState([]);
  const [listStatusShow, setListStatusShow] = useState([]);
  const [visibleModalAddAddTracking, setVisibleModalAddAddTracking] = useState(false);
  const [visibleModalPosition, setVisibleModalPosition] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [nameCandidate, setNameCandidate] = useState([]);
  const [phoneCandidate, setPhoneCandidate] = useState([]);
  const [emailCandidate, setEmailCandidate] = useState([]);
  const [descriptionCandidate, setDescriptionCandidate] = useState([]);
  const [commentCandidate, setCommentCandidate] = useState([]);
  const [academicCandidate, setAcademicCandidate] = useState([])
  const [salaryCandidate, setSalaryCandidate] = useState([]);
  const [salaryLettersCandidate, setSalaryLettersCandidate] = useState([]);
  const [languagesCandidate, setLanguagesCandidate] = useState([]);
  const [technologiesCandidate, setTechnologiesCandidate] = useState([]);
  const [categoryCandidate, setCategoryCandidate] = useState([]);
  const [idCandidate, setIdCandidate] = useState(id_candidate);
  const [multipleStatus, setMultipleStatus] = useState([]);
  const [dateInterview, setDateInterview] = useState(null);
  const [placeHolderDate, setPlaceHolderDate] = useState('No puede seleccionar');
  const [placeHolderDateIn, setPlaceHolderDateIn] = useState('Seleccione Fecha');
  const [messageShowRegister, setMessageShowRegister] = useState('Mostrando solo últimos seguimientos por cada vacante...')
  const [nameBtnShowRegister, setNameBtnShowRegister] = useState('Mostrar todos')
  const [actionBtnShowRegister, setActionBtnShowRegister] = useState(false)
  const [callingAgain, setCallingAgain] = useState(true);
  const [nameCheckCalling, setNameCheckCalling] = useState('');
  const [valueDecimalTarifa, setValueDecimalTarifa]= useState('');

  const [dateInterviewData, setDateInterviewData] = useState(null);
  const [disabledDateInterview, setDisabledDateInterview] = useState(true);
  const [disabledPosition, setDisabledPosition] = useState(false);
  const [requiredDateInterview, setRequiredDateInterview] = useState(false);
  const [requiredDateIncorporation, setRequiredDateIncorporation] = useState(false);
  const [requiredDateOut, setRequiredDateOut] = useState(false);

  const [dateIncorporation, setDateIncorporation] = useState(null);
  const [dateIncorporationData, setDateIncorporationData] = useState(null);
  const [showdateIncorporation, setShowDateIncorporation] = useState(false);
  const [showdateOut, setShowDateOut] = useState(false);

  const [showInfoReject, setShowInfoReject] = useState(false);
  const [showInfoRejectCand, setShowInfoRejectCand] = useState(false);
  const [showInfoConfirmInterview, setShowInfoConfirmInterview] = useState(false);

  const [dateOut, setDateOut] = useState(null);
  const [dateOutData, setDateOutData] = useState(null);
  const [checkedValuesReject, setCheckedValuesReject] = useState([]);
  const [checkedValuesConfirmInterview, setCheckedValuesConfirmInterview] = useState();
  
  const [listPositionData, setListPositionData] = useState([]);
  const [listPositionDataShow, setListPositionDataShow] = useState([]);
  const [listPositionId, setListPositionId] = useState([]);
  const [listPositionToAsignar, setListPositionToAsignar] = useState([]);
  const [listPositionAllInfo, setListPositionAllInfo] = useState([]);
  const [listPositionShow, setListPositionShow] = useState([]);
  const [listPositionInfo, setListPositionInfo] = useState([]);
  const [listReasenReject, setListReasenReject] = useState([]);
  const [listReasenRejectCandidate, setListReasenRejectCandidate] = useState([]);
  const [listConfirmInterview, setListConfirmInterview] = useState([]);

  const [hasPositionInfo, setHasPositionInfo] = useState(false);
  const [multiplePosition, setMultiplePosition] = useState('');
  const [multiplePositionAdd, setMultiplePositionAdd] = useState([]);
  const [languages, setLanguages] = useState('');
  const [listFileShow, setListFileShow] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [visibleModalBussyEdit, setVisibleModalBussyEdit] = useState(false);
  const [userEditName, setUserEditName] = useState('');
  const [userEditDate, setUserEditDate] = useState('');
  const [disableTrackingSubmitButton, setDisableTrackingSubmitButton] = useState(false);
  const [visibleModalEditTracking, setVisibleModalEditTracking] = useState(false);
  const [trackingIdEdit, setTrackingIdEdit] = useState('');
  const [numberInterview, setNumberInterview] = useState('');
  const [idTracking, setIdTracking] = useState('');
  const [visibleModalDeleteTracking, setVisibleModalDeleteTracking] = useState(false);
  
  const [idFile, setIdFile] = useState('');
  const [nameFile, setNameFile] = useState('');
  const [visibleModalDeleteFile, setVisibleModalDeleteFile] = useState(false);

  const [idPosition, setIdPosition] = useState('');
  const [namePosition, setNamePosition] = useState('');
  const [visibleModalDesasociarPosition, setVisibleModalDesasociarPosition] = useState(false);

  const [disabledButton, setDisabledButton] = useState(false);

  const [positionsPast, setPositionPast] = useState([]);
  const [visibleAlertRejectPast, setVisibleAlertRejectPast] = useState(false);
  const [dateRejectCandidatePast, setDateRejectCandidatePast] = useState('');
  const [userRejectCandidatePast, setUserRejectCandidatePast] = useState('');
  
  useEffect(() => {
    getListTracking();
    getListStatus();
    getListPositionCandidate();
    //getListPositionAll();
    getListFile();
    getListPositionAsignar();
    getListReasenReject();
    getPositionPast();
    getListConfirmInterview();
  }, [])

  useEffect(() => {
    if (!visibleModalEditTracking) {
      setLoading(true);
      getListTracking();
    }

  }, [visibleModalEditTracking])

  const getListTracking = async () => {

    try {
      let dataList = [];
      let dataListLast = [];
      get('api/candidate/history/' + id_candidate).then((result) => {
        //Todos
        if (result.data.length > 0) {
            result.data.forEach(element => {

              let statusNameShow = <span>{element.status_name}</span>;
              //let statusNameShow = (element.status_name == 'Aceptado') ? <p><span>{element.status_name}</span><br/>{"Fecha de incorporación: "}{element.incorporation_date}</p> : <span>{element.status_name}</span>;
            
              let actionSplit = (element.action).split(', ');
              let actions = actionSplit.map(action => {
                return `${action}<br>`
              })
              let actionsJoin = actions.join("");

              const GetButtons = () => {
                let deleteBtn = '';
                if (perfilActive === 'Administrador'){
                  deleteBtn =   <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar' onClick={() => openModalDeleteTracking(element.id_tracking)}><i className= "icon-trash"></i></Button>
                }
                let editBtn = '';
                if (element.status_name != 'Registrado'){
                  editBtn = <Button style={{ padding: 0, margin: 10, hight: '35px', width: '35px' }} title='Editar' onClick={() => openModalTrackingEdit(element.id_tracking)}  color='red'><i className="icon-pencil"></i></Button>
                }

                return (
                  <ButtonGroup>
                    {editBtn}
                    {deleteBtn}
                </ButtonGroup> 
                )
              }

              let item = {
                "id": element.id_tracking,
                "date": element.date_register,
                "autor": <p><span style={{ fontWeight: 'bold' }}>{element.name}</span><br/>{element.date_register}</p>,
                "autor_id": element.id_user,
                "autor_name": element.name,
                "comment": element.comment,
                "action": <div dangerouslySetInnerHTML={{ __html: actionsJoin }}></div>,
                "status_id": element.status_id,
                "status_name": <p style={{ color: element.status_color }}>{statusNameShow}</p>,
                "status_color": element.status_color,
                "position": element.name_position,
                "number_identity": element.number_identity,
                "number_interview": element.number_interview,
                "date_interview": element.date_interview,
                "name_candidate": element.name_candidate + ' ' + element.lastname + ' ' + element.second_lastname,
                "name_cliente": element.name_cliente ?? '',
                "incorporation_date": element.incorporation_date ?? '',
                "out_date": element.out_date ?? '',
                "actions": <GetButtons />
              }
              if(element.date_inerview_1) {
                item.date_interview = "Entrevista 1:  " + element.date_inerview_1;
                item.number_interview = 1;
              } else if(element.date_inerview_2) {
                item.date_interview = "Entrevista 2:  " + element.date_inerview_2;
                item.number_interview = 2;
              } else if(element.date_inerview_3) {
                item.date_interview = "Entrevista 3:  " + element.date_inerview_3;
                item.number_interview = 3;
              } else if(element.date_inerview_4) {
                item.date_interview = "Entrevista 4:  " + element.date_inerview_4;
                item.number_interview = 4;
              } else if(element.date_inerview_5) {
                item.date_interview = "Entrevista 5:  " + element.date_inerview_5;
                item.number_interview = 5;
              }
              
              dataList.push(item);
            });
            
            setNameCandidate((result.data[0].name_candidate ?? '') + ' ' + (result.data[0].lastname ?? '') + ' ' + (result.data[0].second_lastname ?? ''))
            setAcademicCandidate(result.data[0].academic)
            setPhoneCandidate(result.data[0].phone)
            setEmailCandidate(result.data[0].email)
            setCommentCandidate(result.data[0].comment_client)
            setDescriptionCandidate(result.data[0].description)
            setCallingAgain(result.data[0].calling_again)
            if(result.data[0].calling_again){
              setNameCheckCalling('Disponible para llamada')
            }else{
              setNameCheckCalling('No volver a llamar')
            }
            
            setSalaryLettersCandidate(result.data[0].salary_letters)

            if (result.data[0].salary) {
              setSalaryCandidate(result.data[0].salary + ' €')
            }

            //idiomas 
            getListLanguages()
              .then((data) => {
                let candidateLanguages = ""
                if (result.data[0].languages != null) {
                  candidateLanguages = result.data[0].languages.split(',').map((x) => {
                    return data.find((language => language.id == x));
                  }).map(language => language.name)
                    .join(", ");
                }
                setLanguagesCandidate(candidateLanguages)
              })
              .catch(err => console.log( err));

            getListTechnologies()
              .then((data) => {
                let candidateTechnologies = ""
                if (result.data[0].technology != null) {
                  candidateTechnologies = result.data[0].technology.split(',').map((x) => {
                    return data.find((technology => technology.id == x));
                  }).map(technology => technology.name)
                    .join(", ");
                }
                setTechnologiesCandidate(candidateTechnologies)
              })
              .catch(err => console.log( err));

            setCategoryCandidate(result.data[0].category)
            setListCandidateTracking(dataList);
            setLoading(false);
        }

        //Ultimos status
        if (result.data_last_status.length > 0) {
          result.data_last_status.forEach(element => {

            let statusNameShow = <span>{element.status_name}</span>;
            //let statusNameShow = (element.status_name == 'Aceptado') ? <p><span>{element.status_name}</span><br/>{"Fecha de incorporación: "}{element.incorporation_date}</p> : <span>{element.status_name}</span>;
            
            let actionSplit = (element.action).split(', ');
            let actions = actionSplit.map(action => {
              return `${action}<br>`
            })
            let actionsJoin = actions.join("");

            const GetButtons = () => {
              let deleteBtn = '';
              if (perfilActive === 'Administrador'){
                deleteBtn =   <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar' onClick={() => openModalDeleteTracking(element.id_tracking)}><i className= "icon-trash"></i></Button>
              }
              let editBtn = '';
              if (element.status_name != 'Registrado'){
                editBtn = <Button style={{ padding: 0, margin: 10, hight: '35px', width: '35px' }} title='Editar' onClick={() => openModalTrackingEdit(element.id_tracking)}  color='red'><i className="icon-pencil"></i></Button>
              }

              return (
                <ButtonGroup>
                  {editBtn}
                  {deleteBtn}
              </ButtonGroup> 
              )
            }

            let item = {
              "id": element.id_tracking,
              "date": element.date_register,
              "autor": <p><span style={{ fontWeight: 'bold' }}>{element.name}</span><br/>{element.date_register}</p>,
              "autor_id": element.id_user,
              "autor_name": element.name,
              "comment": element.comment,
              "action": <div dangerouslySetInnerHTML={{ __html: actionsJoin }}></div>,
              "status_id": element.status_id,
              "status_name": <p style={{ color: element.status_color }}>{statusNameShow}</p>,
              "status_color": element.status_color,
              "position": element.name_position,
              "number_identity": element.number_identity,
              "number_interview": element.number_interview,
              "date_interview": element.date_interview,
              "name_candidate": element.name_candidate + ' ' + element.lastname + ' ' + element.second_lastname,
              "name_cliente": element.name_cliente ?? '',
              "incorporation_date": element.incorporation_date ?? '',
              "out_date": element.out_date ?? '',
              "actions": <GetButtons />
            }
            if(element.date_inerview_1) {
              item.date_interview = "Entrevista 1:  " + element.date_inerview_1;
              item.number_interview = 1;
            } else if(element.date_inerview_2) {
              item.date_interview = "Entrevista 2:  " + element.date_inerview_2;
              item.number_interview = 2;
            } else if(element.date_inerview_3) {
              item.date_interview = "Entrevista 3:  " + element.date_inerview_3;
              item.number_interview = 3;
            } else if(element.date_inerview_4) {
              item.date_interview = "Entrevista 4:  " + element.date_inerview_4;
              item.number_interview = 4;
            } else if(element.date_inerview_5) {
              item.date_interview = "Entrevista 5:  " + element.date_inerview_5;
              item.number_interview = 5;
            }
            
            dataListLast.push(item);
          });

          setListCandidateTrackingLast(dataListLast);
          //Comienza mostrando los utlimos
          setListCandidateTrackingShow(dataListLast)
      }

      });
    } catch (error) {
      console.log(error)
    }
  }

  const getPositionPast = async () => {

    try {
      let dataList = [];
      get('api/position/past/' + id_candidate).then((result) => {
        //Todos
        if (result.data.length > 0) {
            result.data.forEach(element => {
              let item = {
                'position_id' : element.position_id,
                'date_register' : element.date_register,
                'user_id' : element.user_id,
                'name_user': element.name
              }

              dataList.push(item);
            })
            setPositionPast(dataList)

        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const openModalDeleteTracking = (idTracking) => {
    setIdTracking(idTracking);
    setVisibleModalDeleteTracking(true);
  }

  const openModalDeleteFile = (idFile, nameFile) => {
    setIdFile(idFile);
    setNameFile(nameFile);
    setVisibleModalDeleteFile(true);
  }

  const openModalDesasociarPosicion = (idPosition, namePosition) => {
    setIdPosition(idPosition);
    setNamePosition(namePosition);
    setVisibleModalDesasociarPosition(true);
  }

  // Posiciones ------------------------------------------------------------------------------------------------------------------------------------- Posiciones


  const getListLanguages = async () => {

    return new Promise((resolve, reject) => {
      get("api/language/list")
        .then(result => resolve(result.data))
        .catch(err => reject(err))
    });
  }
  const getListTechnologies = async () => {

    return new Promise((resolve, reject) => {
      get("api/technology/list")
        .then(result => resolve(result.data))
        .catch(err => reject(err))
    });
  }

  const getListPositionCandidate = async () => {
    try {
      let dataListPositionShow = [];
      let dataListPositionInfo = [];
      get('api/position/list/' + id_candidate).then((result) => {

        if (result.data.length > 0) {
          result.data.forEach(element => {
            let item = { value: element.id, label: <div>{element.name}<span><Badge color={getColor(element.state_position)}>{element.state_position}</Badge></span></div> };
            dataListPositionShow.push(item);

            let itemInfo = {
              id: element.id,
              data: element.name,
              name: element.name
            };

            dataListPositionInfo.push(itemInfo)
          });
          setListPositionId(result.data);
          setListPositionShow(dataListPositionShow);
          setListPositionInfo(dataListPositionInfo);
          setHasPositionInfo(true)
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getListPositionAsignar = async () => {
    try {
      let dataListPositionAsignar = [];
      get('api/position/list/assign/'+ id_candidate + '/' + idUserSesion).then((result) => {

        if (result.data.length > 0) {
          //console.log(result.data)
          result.data.forEach(element => {
            let item = { 
              value: element.id, 
              label: element.name+' - ('+element.name_client+')'
            };
            dataListPositionAsignar.push(item);
            
          });
          setListPositionToAsignar(dataListPositionAsignar);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getColor = (statePosition) => {
    let color;
    switch (statePosition) {
      case "Ganada":
        color = "#28a745";
        break;
      case "Perdido/Cerrado":
        color = "#dc3545";
        break;
      case "En proceso":
        color = "#5bc0de";
        break;
      case "Parada":
        color = "#ffc107";
        break;
    }
    return color;
  }


  //Archivos ------------------------------------------------------------------------------------------------------------------------------------- Archivos


  const getListFile = async () => {
    let dataListFileShow = [];
    get('api/archive/candidate/' + id_candidate).then((result) => {
      if (result.data.length > 0) {
        result.data.forEach(element => {
          //let item = {value: element.id, label: element.name};
          dataListFileShow.push(element);
        });
        setListFileShow(dataListFileShow);
      }
    });

  }

  const handleCustomUploadButtonClick = () => {
    // Simular el clic en el input file oculto
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const fileUp = event.target.files;
    //console.log("archivo", fileUp);
    const dataUp = {
      candidate_id: id_candidate,
      file: fileUp
    }
    //console.log("data", dataUp);

    postWithDataMultiform("api/archive/candidate", dataUp).then((result) => {
      if (result.success == true) {
        toast.success("Archivo agregado de forma satisfactoria!");
      } else {
        toast.warning('Se ha producido un error al subir el archivo');
      }
    });
    setSelectedValue('');
    getListFile();
  };

  // Motivos de Rechazo

  
  const getListReasenReject = async () => {
    let dataListShow = [];
    let dataListId = [];
    let dataListShowCandidate = [];
    let dataListIdCandidate = [];

    get('api/rejection_reason/list').then((result) => {
      console.log(result);
      if (result.data.length > 0) {
        result.data.forEach(element => {
          if(element.type_rejection == "Normal"){
            dataListShow.push(element);
            dataListId.push(element.id)
          }else{
            dataListShowCandidate.push(element);
            dataListIdCandidate.push(element.id)
          }
          //let item = {value: element.id, label: element.name};
          
        });
        setListReasenReject(dataListShow);
        setListReasenRejectCandidate(dataListShowCandidate);
      }
    });

  }

  // Confirmación de Entrevista
  const getListConfirmInterview = async () => {
    let dataListShow = [];
    let dataListId = [];

    let dataListShowCandidate = [];
    let dataListIdCandidate = [];

    get('api/confirm_interview/list').then((result) => {
      console.log(result);
      if (result.data.length > 0) {
        result.data.forEach(element => {
            dataListShow.push(element);
            dataListId.push(element.id)
          
        });
        setListConfirmInterview(dataListShow);
      }
    });

  }

  // Modal ------------------------------------------------------------------------------------------------------------------------------------------ Modal

  const openModalAddTracking = () => setVisibleModalAddAddTracking(!visibleModalAddAddTracking);
  const openModalAddPosition = () => setVisibleModalPosition(!visibleModalPosition);
  const openModalTrackingEdit = (id) => {
    setTrackingIdEdit(id);
    setVisibleModalEditTracking(!visibleModalEditTracking);
  }

  const getListStatus = async () => {
    try {
      let dataListStatusShow = [];
      get("api/status/list").then((result) => {

        if (result.data.length > 0) {
          result.data.forEach(element => {
            let item = { value: element.id, label: element.name };
            dataListStatusShow.push(item);
          });
          setListStatusShow(dataListStatusShow);
          setListStatusId(result.data);
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectNumberInterview = (data) => {
    setNumberInterview(data.value);
    if(data.value){
      setRequiredDateInterview(true);
      setDisabledDateInterview(false);
      setPlaceHolderDate('Seleccione Fecha')
    } else {
      setRequiredDateInterview(false);
      setDisabledDateInterview(true);
      setPlaceHolderDate('No puede seleccionar')
    }
  }

  const handleSelectStatus = (data) => {
    let idsRechazos = [1,4,5];

    setMultipleStatus(data.value)
    //console.log("ddd",data.value )
    //Aceptada
    if(data.value == 7){
      setShowDateIncorporation(true)
      setRequiredDateIncorporation(true)
      setShowInfoReject(false)
      setShowInfoRejectCand(false)
      setShowInfoConfirmInterview(false);
    }else if(data.value == 11){
      setShowDateOut(true)
      setRequiredDateOut(true)
      setShowInfoReject(false)
      setShowInfoRejectCand(false)
      setShowInfoConfirmInterview(false);
    }else if(idsRechazos.indexOf(data.value) !== -1){
      //Otros rechazos
      setShowInfoReject(true)
      setShowDateIncorporation(false)
      setRequiredDateIncorporation(false)
      setShowInfoRejectCand(false)
      setShowInfoConfirmInterview(false);
    }else if(data.value == 3){
      //Rechazo candidato
      setShowInfoRejectCand(true)
      setShowInfoConfirmInterview(false);
      setShowInfoReject(false)
      setShowDateIncorporation(false)
      setRequiredDateIncorporation(false)
    }else if(data.value == 13){
      //Citado para entrevista
      setRequiredDateInterview(true);
      setDisabledDateInterview(false);
      setPlaceHolderDate('Seleccione Fecha')
      setShowInfoConfirmInterview(false);
    }else if(data.value == 6){
      //Entrevistado
      /*
      setRequiredDateInterview(true);
      setDisabledDateInterview(false);
      setPlaceHolderDate('Seleccione Fecha')
      */
      setShowInfoConfirmInterview(true);
    }
    else{
      setShowDateIncorporation(false)
      setRequiredDateIncorporation(false)
      setShowDateOut(false)
      setRequiredDateOut(false)
      setShowInfoReject(false)
      setShowInfoRejectCand(false)
      setShowInfoConfirmInterview(false);
    }
  };

  const handleSelectPosition = (data) => {
    //console.log("position value" + data.value);
    setMultiplePosition(data.value)
    //console.log("multipleStatus" + multiplePosition)
  };

  const handleSelectPositionAdd = (data) => {
    setMultiplePositionAdd(data.value)
    
    //Para mostrar alerta de empleados que ya existieron dentro de la posicion
    const positionFind = positionsPast.find(item => item.position_id === data.value);
    const isIdInArray = positionsPast.some(item => item.position_id === data.value);

    if(isIdInArray){
      setDateRejectCandidatePast(positionFind.date_register);
      setUserRejectCandidatePast(positionFind.name_user);
      setVisibleAlertRejectPast(isIdInArray);
    }else{
      setVisibleAlertRejectPast(false);
    }

  };

  const handleCheckboxChangeReject = (id, isChecked, arrayIds) => {
    console.log("ARRAY ANTES", arrayIds)


    if(isChecked === true){
      let arrayInclude = arrayIds.push(id)
      //setCheckedValuesReject(arrayInclude)
    }else{
      
      const arrayExclude = arrayIds.filter((value) => value !== id);
      setCheckedValuesReject(arrayExclude)
    }
    
  };

  const handleCheckboxChangeConfirmInterview = (id, isChecked) => {
    
    if(isChecked === true){
      console.log(id)
      setCheckedValuesConfirmInterview(id)
    }
  };


  const deleteTracking = () => {
    let user_id = localStorage.getItem('Id');
    try {
      get("api/candidate/tracking/delete/" + idTracking + '/' + user_id).then((result) => {

        setVisibleModalDeleteTracking(false);
        toast.success(`Se elimino de forma satisfactoria.`);
        window.location.href = window.location.href;      
      });
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFile = () => {
    
    try {
      get("api/archive/candidate/delete/" + idFile).then((result) => {

        setVisibleModalDeleteFile(false);
        toast.success(`Se elimino de forma satisfactoria.`);
        window.location.href = window.location.href;           
      });
    } catch (error) {
      console.log(error)
    }
  }

  const disassociatePosition = () => {
    
    try {
      let data = {
        id_position : idPosition,
        id_candidate : idCandidate
      }

      postWithData("api/position/candidate/disassociate", data).then((result) => {

        setVisibleModalDesasociarPosition(false);
        toast.success(`Se desvinculo esta vacante de forma satisfactoria.`);
        window.location.href = window.location.href;             
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeDateInterview = date => {
    //console.log("fecha_entrevista", date)
    let dateInterview = new Date(date);
    let month = ((dateInterview.getMonth() + 1) < 10) ? "0" + (dateInterview.getMonth() + 1) : (dateInterview.getMonth() + 1);
    let time = dateInterview.getHours()+':'+dateInterview.getMinutes()+':'+dateInterview.getSeconds();
    let dateFinal = dateInterview.getFullYear() + "-" + month + "-" + dateInterview.getDate()+' '+time;
    setDateInterview(dateInterview)
    //console.log("fecha_entrevista", dateFinal)
    setDateInterviewData(dateFinal)
  };

  const handleChangeDateIncorporation = date => {
    let dateIncorporation = new Date(date);
    let month = ((dateIncorporation.getMonth() + 1) < 10) ? "0" + (dateIncorporation.getMonth() + 1) : (dateIncorporation.getMonth() + 1);
    let dateFinal = dateIncorporation.getFullYear() + "-" + month + "-" + dateIncorporation.getDate()
    setDateIncorporation(dateIncorporation)
    setDateIncorporationData(dateFinal)
  };

  const handleChangeDateOut = date => {
    let dateOut = new Date(date);
    let month = ((dateOut.getMonth() + 1) < 10) ? "0" + (dateOut.getMonth() + 1) : (dateOut.getMonth() + 1);
    let dateFinal = dateOut.getFullYear() + "-" + month + "-" + dateOut.getDate()
    setDateOut(dateOut)
    setDateOutData(dateFinal)
  };

  const handleToggleShowRegister = () => {
    if(actionBtnShowRegister == false){
      setListCandidateTrackingShow(listCandidateTrackingLast)
      setMessageShowRegister('Mostrando solo últimos seguimientos por cada vacante...')
      setNameBtnShowRegister('Mostrar todos')
      setActionBtnShowRegister(true)
    }else{
      setListCandidateTrackingShow(listCandidateTracking)
      setMessageShowRegister('Mostrando todo el seguimiento...') 
      setNameBtnShowRegister('Mostrar últimos seguimientos')
      setActionBtnShowRegister(false)
    }
    
  }

  const handleCheckboxCallingChange = () => {
    let checkboxCall = !callingAgain;
    
    if(checkboxCall == false){
      setCallingAgain(false)
      setNameCheckCalling('No volver a llamar')
    }else{
      setCallingAgain(true)
      setNameCheckCalling('Disponible para llamada')
    }

    let data = {
      calling_again : checkboxCall,
      id_candidate : idCandidate
    }

    postWithData("api/candidate/notcalling", data).then((result) => {
      //toast.success(`Se desvinculo esta vacante de forma satisfactoria.`);
      //window.location.href = window.location.href;             
    });
    
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    setDisableTrackingSubmitButton(true);
    setLoadingSubmit(true);
    let dataTracking = {
      ...data,
      candidate_id: idCandidate,
      position_id: multiplePosition,
      status_id: multipleStatus,
      date_interview: dateInterviewData,
      number_interview: numberInterview,
      date_incorporation : dateIncorporationData,
      date_out : dateOutData,
      rejection_reason: checkedValuesReject,
      confirm_interview: checkedValuesConfirmInterview
    }
    
    postWithData("api/tracking/add", dataTracking).then((result) => {
      setLoadingSubmit(false);
      if (result.success === true) {
        //console.log("Cierra modal")
        setVisibleModalAddAddTracking(!visibleModalAddAddTracking);
        window.location.href = window.location.href;
      } else {
        setDisableTrackingSubmitButton(false);
        toast.error("Hubo un error al agregar historial")
        setVisibleModalAddAddTracking(!visibleModalAddAddTracking);
      }
      //console.log('exito al crear tracking', result);//eliminar
    })
    .finally(()=>setDisableTrackingSubmitButton(false));
  };

  const onSubmitCandidatePosition = (data, e) => {
    e.preventDefault();
    let dataPositionCandidate = {
      candidate_id: idCandidate,
      position_id: multiplePositionAdd
    }

    postWithData("api/position_candidate/store", dataPositionCandidate).then((result) => {

      if (result.success === true) {
        setVisibleModalPosition(!visibleModalPosition);
        toast.success("Candidato propuesto para la vacante");
        window.location.href = window.location.href;
      } else {
        toast.error("Hubo un error al proponer candidato")
      }

    });

  };

  const closeModal = () => {
    setVisibleModalAddAddTracking(!visibleModalAddAddTracking);
  };

  const closeModalAddPosition = () => {
    setVisibleModalPosition(!visibleModalPosition);
  };

  const closeModalDeleteTracking = () => {
    setVisibleModalDeleteTracking(!visibleModalDeleteTracking);
    setIdTracking('');
  };

  const closeModalDeleteFile = () => {
    setVisibleModalDeleteFile(!visibleModalDeleteFile);
    setIdFile('');
    setNameFile('');
  };

  
  const closeModalDesasociarPosition = () => {
    setVisibleModalDesasociarPosition(!visibleModalDesasociarPosition);
    setIdPosition('');
    setNamePosition('');
  };


  // Tabla --------------------------------------------------------------------------------------------------------------------------------------------- Tabla


  const tableColumns = [
    {
      name: 'Recruiter',
      selector: 'autor',
      sortable: true,
      center: true,
    },
    {
      name: 'Fecha de Entrevista',
      selector: 'date_interview',
      sortable: true,
      center: true,
    },
    {
      name: 'Vacante',
      selector: 'position',
      sortable: true,
      center: true,
      minWidth: '200px'
    },
    {
      name: 'Cliente',
      selector: 'name_cliente',
      sortable: true,
      center: false,
      minWidth: '100px'
    },
    {
      name: 'Último Status',
      selector: 'status_name',
      sortable: true,
      center: true,
      minWidth: '200px'
    },
    /*{
      name: 'Acciones Realizadas',
      selector: 'action',
      sortable: true,
      center: false,
    },*/
    {
      name: 'Fecha de Incorporación',
      selector: 'incorporation_date',
      sortable: true,
      center: true,
    },
    {
      name: 'Comentarios',
      selector: 'comment',
      sortable: true,
      center: true,
    },
    {
      name: 'Acciones',
      selector: 'actions',
      sortable: true,
      center: true,
    },
  ]


  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);


  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success("Successfully Deleted !")
      }
    };
    return <button key="delete" className="btn btn-danger" onClick={handleDelete}>Delete</button>;
  }, [data, selectedRows, toggleCleared]);

  const handleEditAnywaysClick = () => {
    let dataUserTrack = {
      url: '/candidate/edit/' + id_candidate,
      // url: window.location.pathname,
      user_id: localStorage.getItem('Id')
    }

    postWithData("api/user/insert_track", dataUserTrack).then((result) => {
      if (result.success === true) {
        window.location.href = '/candidate/edit/' + id_candidate;
      }
    });
  }

  const handleEditCandidateButtonClick = () => {
    let dataUserTrack = {
      url: '/candidate/edit/' + id_candidate,
      // url: window.location.pathname,
      user_id: localStorage.getItem('Id')
    }

    postWithData("api/user/track", dataUserTrack).then((result) => {
      if (result.success === true) {
        if (result.message === 'ok') {
          window.location.href = '/candidate/edit/' + id_candidate;
        } else {
          const date = new Date(result.data.updated_at);
          const formattedDate = format(date, "dd-MM-yyyy HH:mm:ss");
          setUserEditDate(formattedDate)
          setUserEditName(result.data.user_name)
          openModalBussyEdit();
        }
        //console.log(result)
      } else {
        console.log(result)
      }
    });
  }

  const handleChangeDecimal = (event) => {
    const { value } = event.target;

    // Expresión regular para permitir solo números y un punto opcional para los decimales
    const regex = /^\d*\.?\d*$/;

    if (regex.test(value) || value === '') {
      setValueDecimalTarifa(value)
      // Llama a la función de cambio pasada como prop
      /*if (this.props.onChange) {
        this.props.onChange(value);
      }*/
    }
  };

  const openModalBussyEdit = () => {
    setVisibleModalBussyEdit(!visibleModalBussyEdit);
  }

  return (
    <Fragment>
      <Breadcrumb parent="" title="Historial de Candidato" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Row>
              <Col sm="12" md="6">
                <h1 className={`${callingAgain ? '' : 'text-through'}`}>{nameCandidate}</h1>
                <div className={`checkbox ${callingAgain ? 'checkbox-solid-success' : 'checkbox-solid-danger'}`}>
                  &nbsp;&nbsp;&nbsp;&nbsp;<Input id="solid1" type="checkbox" checked={callingAgain} onChange={handleCheckboxCallingChange} />
                  <Label for="solid1">{nameCheckCalling}</Label>
                </div>
                {/*<h6>{academicCandidate} / {categoryCandidate}</h6>*/}
              </Col>
              <Col sm="12" md="6">
                <ButtonGroup className="pull-right">
                  <Button color="primary" onClick={handleEditCandidateButtonClick}>
                    {/* <Link to={`/candidate/edit/${id_candidate}`} className="text-white"> */}
                    <i className="fa fa-pencil"></i> Editar
                    {/* </Link> */}
                  </Button>
                  <Button color="primary" onClick={handleCustomUploadButtonClick}>
                    <span>
                      <i className="fa fa-plus text-white"></i>{" "}
                    </span>
                    {" Archivo"}
                  </Button>
                  {/* Input file oculto */}
                  <input
                    type="file"
                    id="fileInput"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                  />
                  <Button color="primary" onClick={openModalAddPosition}>
                    <span>
                      <i className="icon-tag text-white"></i>{" "}
                    </span>{"Vacante"}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="12" md="4">
                <p><span className='title'>{"Email: "}</span>{emailCandidate}</p>
                <p><span className='title'>{"Telefono: "}</span>{phoneCandidate}</p>
                <p><span className='title'>{"Pretención Salarial: "}</span>{salaryCandidate}</p>
                <p><span className='title'>{"Tarifa Autónomo: "}</span>{salaryLettersCandidate}</p>
                <p><span className='title'>{"Idiomas: "}</span>{languagesCandidate}</p>
                <p><span className='title'>{"Tecnologías: "}</span>{technologiesCandidate}</p>
              </Col>
              <Col sm="12" md="4">
                <div>
                  <span className='title'>Postulado a:</span>
                  <ul>
                    {listPositionInfo.map((item, index) => (
                      <li key={item.id}>
                        <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Desvincuar posición ' onClick={() => openModalDesasociarPosicion (item.id, item.name)}><i className= "icon-trash"></i></Button>
                        {item.data}
                        </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div>
                  <span className='title'>Archivos</span>
                  <ul>
                    {listFileShow.map((item, index) => (
                      <li key={item.id}>
                        <Button style={{ padding: 5, hight: '35px', width: '35px' }} color='red'  title='Eliminar Archivo' onClick={() => openModalDeleteFile(item.id, item.name)}><i className= "icon-trash"></i></Button>
                        <a href={item.path} target="_blank"> {item.name} - {item.created_at}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6">
                <p></p>
                <p><span className='title'>{"Observaciones / Comentarios: "}</span>{descriptionCandidate}</p>
              </Col>
              <Col sm="12" md="6">
                <p></p>
                <p><span className='title'>{"Comentarios para cliente "}</span>{commentCandidate}</p>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col>
                <Button className="btn-search" 
                  color={actionBtnShowRegister ? 'primary' : 'primary' } 
                  onClick={handleToggleShowRegister}>{nameBtnShowRegister}
                </Button>
              </Col>
              <Col>
                <span className="pull-right">
                  <Button color="primary" onClick={openModalAddTracking}>
                    <span>
                      <i className="fa fa-plus text-white">
                      </i>
                    </span>{" Acciones"}
                  </Button>
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                
                <div>{messageShowRegister}</div>
                <DataTable className="table-responsive"
                  columns={tableColumns}
                  data={listCandidateTrackingShow}
                  persistTableHead
                  searchable={true}
                  pagination
                  noDataComponent={<div>No hay datos disponibles</div>}
                  progressPending={loading}
                  progressComponent={<div className="loader-box"><div className="loader-9"></div></div>} // Componente personalizado para el indicador de carga
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>

      <Modal size="lg" isOpen={visibleModalAddAddTracking} toggle={openModalAddTracking}>
        <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={openModalAddTracking}>
            {"Agregar Historia al Candidato"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="6">
                <div className="col-form-label pt-0">{"Estado"}:</div>
                <FormGroup>
                  <Select
                    onChange={handleSelectStatus}
                    options={listStatusShow}
                    id="status" name="status"
                    required
                    innerRef={register({ required: true })} />
                </FormGroup>
              </Col>
              <Col lg="3">
                <div className="col-form-label pt-0">{"Número Entrevista"}:</div>
                <FormGroup>
                  <Select
                    onChange={handleSelectNumberInterview}
                    options={[
                      { value: "", label: "" },
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                      { value: 3, label: 3 },
                      { value: 4, label: 4 },
                      { value: 5, label: 5 },
                    ]}
                    id="number_interview" name="number_interview"
                    innerRef={register()} />
                </FormGroup>
              </Col>
              <Col lg="3">
                <div className="col-form-label pt-0">{"Fecha de Entrevista"}:</div>
                <FormGroup>
                  <DatePicker 
                    selected={dateInterview} 
                    className="form-control" 
                    disabled={(disabledDateInterview) ? "disabled" : ""} 
                    placeholderText={placeHolderDate}
                    onChange={handleChangeDateInterview}
                    required= {requiredDateInterview}
                    innerRef={register({required: requiredDateInterview})} 
                    showTimeSelect // Habilita la selección de hora
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd h:mm aa"/>
                </FormGroup>
              </Col>
            </Row>
            {showdateIncorporation && (
                <Row>
                  <Col>
                    <div className="col-form-label pt-0">{"Fecha de Incorporación"}:</div>
                    <FormGroup>
                      <DatePicker 
                        selected={dateIncorporation} 
                        className="form-control" 
                        placeholderText={placeHolderDateIn}
                        onChange={handleChangeDateIncorporation}
                        required= {requiredDateIncorporation}
                        type="text"
                        innerRef={register({required: requiredDateIncorporation})} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="col-form-label pt-0">{"Fecha de Salida"}:</div>
                    <FormGroup>
                      <DatePicker 
                        selected={dateOut} 
                        className="form-control" 
                        placeholderText={placeHolderDateIn}
                        onChange={handleChangeDateOut}
                        type="text"
                        innerRef={register()} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="col-form-label pt-0">{"Tarifa Final"}:</div>
                    <FormGroup>
                      <Input className="form-control" 
                      id="agreed_rate" 
                      name="agreed_rate" 
                      type="decimal" 
                      placeholder="" 
                      value={valueDecimalTarifa}
                      innerRef={register({required: requiredDateIncorporation})} 
                      onChange={handleChangeDecimal}/>
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="col-form-label pt-0">{"Tarifa Final (En letras)"}:</div>
                    <FormGroup>
                      <Input className="form-control" id="agreed_rate_letters" 
                      name="agreed_rate_letters" 
                      type="text" placeholder="" 
                      innerRef={register({required: requiredDateIncorporation})} />
                    </FormGroup>
                  </Col>
                </Row>
            )}
            {/*{showdateIncorporation && (
              <Row>
                  <Col>
                    <div className="col-form-label pt-0">{"Producción Total"}:</div>
                    <FormGroup>
                      <Input className="form-control" id="total_production" name="total_production" type="decimal" placeholder="" innerRef={register({required: requiredDateIncorporation})} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="col-form-label pt-0">{"Margen Total"}:</div>
                    <FormGroup>
                      <Input className="form-control" id="total_margen" name="total_margen" type="decimal" placeholder="" innerRef={register({required: requiredDateIncorporation})} />
                    </FormGroup>
                  </Col>
              </Row>
            )}*/}
            {showdateOut && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Fecha de Salida"}:</div>
                  <FormGroup>
                    <DatePicker 
                      selected={dateOut} 
                      className="form-control" 
                      placeholderText={placeHolderDateIn}
                      onChange={handleChangeDateOut}
                      type="text"
                      innerRef={register({required: showdateOut})} />
                  </FormGroup>
                </Col>
                </Row>
            )}

            <Row>
              <Col>
                <div className="col-form-label pt-0">{"Vacante"}:</div>
                <FormGroup>
                  <Select
                    id="position" name='position'
                    onChange={handleSelectPosition}
                    options={listPositionShow}
                    required={hasPositionInfo}
                    innerRef={register()} />
                </FormGroup>
              </Col>
            </Row>
            {showInfoReject && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Motivo de Rechazo"}:</div>
                  <Row>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {listReasenReject.map((item) => (
                      <Col key={item.id}>
                        <FormGroup>
                        <Input id={`checkbox-${item.id}`} 
                        type="checkbox" 
                        //checked={checkedValuesReject.includes(item.id)}
                        onChange={(e) => handleCheckboxChangeReject(item.id, e.target.checked, checkedValuesReject)} />
                        <Label htmlFor={`checkbox-${item.id}`}>{item.name}</Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
            {showInfoRejectCand && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Motivo de Rechazo"}:</div>
                  <Row>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {listReasenRejectCandidate.map((item) => (
                      <Col key={item.id}>
                        <FormGroup>
                        <Input id={`checkbox-${item.id}`} 
                        type="checkbox" 
                        //checked={checkedValuesReject.includes(item.id)}
                        onChange={(e) => handleCheckboxChangeReject(item.id, e.target.checked, checkedValuesReject)} />
                        <Label htmlFor={`checkbox-${item.id}`}>{item.name}</Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
            {showInfoConfirmInterview && (
              <Row>
                <Col>
                  <div className="col-form-label pt-0">{"Confirmación de Entrevista"}:</div>
                  <Row>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {listConfirmInterview.map((item) => (
                      <Col key={item.id}>
                        <FormGroup>
                        <Input id={`radio-confirm-${item.id}`}
                        type="radio" 
                        name="confirmInterview" 
                        onChange={(e) => handleCheckboxChangeConfirmInterview(item.id)} />
                        <Label htmlFor={`radio-confirm-${item.id}`}>{item.name}</Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <FormGroup>
                  <Label className="col-form-label pt-0">{"Comentario / Observación"}:</Label>
                  <Input type="textarea" className="form-control btn-square" name="comment" rows="3" innerRef={register()} />
                </FormGroup>
              </Col>
            </Row>
            {<div className={`content ${loadingSubmit ? 'visible loader-box' : 'hidden'}`}><div className="loader-8"></div></div>}
          </ModalBody>
          <ModalFooter>
            <Button disabled={disableTrackingSubmitButton} color="primary mr-1" type="submit">{"Guardar Historia"}</Button>
            <Button onClick={closeModal} color="secondary">{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

      < ModalEditTracking 
        trackingIdEdit={trackingIdEdit} 
        listStatusShow={listStatusShow} 
        listPositionShow={listPositionShow} 
        setVisibleModalEditTracking={setVisibleModalEditTracking} 
        visibleModalEditTracking={visibleModalEditTracking} />

      <Modal size="lg" isOpen={visibleModalPosition} toggle={openModalAddPosition}>
        <Form className="theme-form" onSubmit={handleSubmit(onSubmitCandidatePosition)}>
          <ModalHeader toggle={openModalAddPosition}>
            {"Proponer candidato a un vacante"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <div className="col-form-label pt-0">{"Vacante"}:</div>
                <FormGroup>
                  <Select
                    options={listPositionToAsignar}
                    id="positionadd"
                    onChange={handleSelectPositionAdd}
                    isSearchable={true}
                    innerRef={register({ required: true })} />
                </FormGroup>
                
                {visibleAlertRejectPast && (
                  <div className="alert alert-danger mt-2" role="alert">
                    ¡Alerta! Este candidato ya fue aplicado y rechazado para esta posición en una oportunidad anterior. Fue rechazado por {userRejectCandidatePast} el {dateRejectCandidatePast}
                  </div>
                  )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeModalAddPosition} color="secondary">{"Cancelar"}</Button>
            <Button color="primary mr-1" type="submit">{"Proponer"}</Button>
          </ModalFooter>
        </Form>
      </Modal>
      
      <Modal size="lg" isOpen={visibleModalBussyEdit} toggle={openModalBussyEdit}>
        <ModalHeader toggle={openModalBussyEdit}>
          {"El candidato está siendo editado"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <h6>{`El candidato está siendo o fue editado por ${userEditName} el ${userEditDate}`}</h6>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={openModalBussyEdit} color="secondary">{"Cancelar"}</Button>
          <Button color="primary mr-1" onClick={handleEditAnywaysClick}>
            {/* <Link to={`/candidate/edit/${id_candidate}`} className="text-white"> */}
            <i className="fa fa-pencil"></i> Editar Igualmente
            {/* </Link> */}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={visibleModalDeleteTracking} toggle={openModalDeleteTracking}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalDeleteTracking}>
            {"Elimiar Seguimiento"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                {"¿Estas seguro de eliminar este seguimiento?"}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={deleteTracking} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModalDeleteTracking}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={visibleModalDeleteFile} toggle={openModalDeleteFile}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalDeleteFile}>
            {"Elimiar Archivo"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                {"¿Estas seguro de eliminar el archivo "+nameFile+"?"}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={deleteFile} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModalDeleteFile}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={visibleModalDesasociarPosition} toggle={openModalDesasociarPosicion}>
        <Form className="theme-form">
          <ModalHeader toggle={openModalDesasociarPosicion}>
            {"Quitar Vacante"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                {"¿Estas seguro que quiere dar de baja a la vacante "+namePosition+" para este candidato?"}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary mr-1" onClick={disassociatePosition} type="button">{"Si"}</Button>
            <Button color="secondary" onClick={closeModalDesasociarPosition}>{"Cancelar"}</Button>
          </ModalFooter>
        </Form>
      </Modal>
      
    </Fragment>
  );
};

export default History;